/* X-SMALL (1) ----------------------------------------------------------------------------------------- */
@media (max-width: 575px)
{
}

/* SMALL (2) ------------------------------------------------------------------------------------------- */
@media (min-width: 576px)
{
}

/* MEDIUM (3) ------------------------------------------------------------------------------------------ */
@media (min-width: 768px)
{
}

/* LARGE (4) ------------------------------------------------------------------------------------------- */
@media (min-width: 992px)
{
}

/* X-LARGE (5) ----------------------------------------------------------------------------------------- */
@media (min-width: 1200px)
{
}

/* GENERAL --------------------------------------------------------------------------------------------- */

.back {
	height: 100vh;
	background-color: #000000;
	top: 0px;
	background-image: url("./imagenes/fondo.png");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: center;
}

.main{	
	padding-top: 30px;
}

.texto {
	text-align: left;
	color: black;
	font-weight: bold;
}

.textod {
	text-align: right;
	font-weight: bold;
}


.boton {
	font-weight: bold;
}

.cap { text-transform: capitalize; }

.loginForm {
	padding-top: 20px;
	background: rgba(0,0,0,0.8);
	color: #fbb448;;
	font-family: 'LatoRegular';
	text-align: center;
	border-radius: 25px;
	border-style: solid;
	border-color: #fbb448;
	box-shadow: 10px 10px 5px #555555;
	padding-bottom: 20px;
}

.inputs {
	border-style: solid;
	border-width: 3px;
	border-color: #fbb448;
	background-color: #dddddd !important;
}

.espacio
{
	height: 70px;
}